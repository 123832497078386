import api from '@/utils/api';
import { sellerStoreId, userId } from '@/utils/auth';

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/users`;

export const verifyOtpApi = ({
  phoneNumber = '',
  password = '',
  email = '',
  otp = '',
  otpAuthToken = '',
}: {
  phoneNumber?: string;
  password?: string;
  email?: string;
  otp?: string;
  otpAuthToken?: string;
}) => {
  const headers: { [key: string]: string } = {};
  headers['otp'] = otp;
  headers['x-auth-token'] = otpAuthToken;
  return api.call({
    url: `${HOST}/forgotPassword`,
    method: 'post',
    data: {
      userPhone: phoneNumber,
      userPasswordHash: password,
      userEmail: email,
    },
    headers,
  });
};
//thi api is for the personal details
export const getPersonalDetailsApi = () => {
  return api.call({
    method: 'get',
    url: `${HOST}/${userId()}/store/${sellerStoreId()}/profile`,
  });
};

export const updatePersonalDetails = (data: any) => {
  return api.call({
    method: 'put',
    url: `${HOST}/${userId()}/store/${sellerStoreId()}/profile`,
    data,
  });
};

export default {
  verifyOtpApi,
  getPersonalDetailsApi,
  updatePersonalDetails,
};
