import cx from '../../utils/class-names';
import { ReactNode } from 'react';

export default function FormError(props: {
  className?: string;
  message?: string;
  show?: boolean;
  children?: ReactNode;
}) {
  const {
    className,
    message = '',
    show = false,
    children,
    ...restProps
  } = props;
  const errorMessage = message || children;
  if (!show) return null;
  return (
    <div
      className={cx('form-field-error text-xs text-red-500', className)}
      {...restProps}
    >
      {errorMessage}
    </div>
  );
}
