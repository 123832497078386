import { useMemo, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import generateContext, { UseGetContextValue } from '@/utils/generate-context';
import useAsync from '@/hooks/use-async';
import SessionApis from '@/apis/sessions';
import OTPApis from '@/apis/otp';
import UserApis from '@/apis/users';
import { COOKIES, getCookieDomain, setCookie } from '@/services/cookies';
import { OrderStatuses, PackageStatus } from '@/constants/enums';

export const useLoginPage = () => {
  const router = useRouter();
  const decode = router.query.redirect_url as string;
  console.log(decodeURIComponent(decode));
  const [otpAuthToken, setOTPAuthToken] = useState<string>('');
  const loginWithPasswordQuery = useAsync(SessionApis.loginWithPasswordApi);
  const sendOTPQuery = useAsync(OTPApis.sendOtpApi);
  const verifyOTPQuery = useAsync(UserApis.verifyOtpApi);
  const resendOTPQuery = useAsync(OTPApis.resendOtpApi);

  const handleLoginWithPassword = useCallback(
    (values: any) => {
      loginWithPasswordQuery.execute(
        {
          phoneNumberEmail: values.phoneNumberEmail,
          password: values.passwordLine1,
        },
        {
          onSuccess: (res: any) => {
            if ([200, 202].includes(res.status)) {
              setCookie(
                null,
                COOKIES.shop101Session,
                JSON.stringify(res.data),
                getCookieDomain(window.location.hostname),
                values.rememberMe ? 180 : null
              );
              setCookie(
                null,
                COOKIES.shop101LastUsedUsername,
                values.phoneNumberEmail,
                getCookieDomain(window.location.hostname),
                180
              );
              if (router.query.client_id) {
                return router.push({
                  pathname: '/freshdesk/login',
                  query: router.query,
                });
              } else if (router.query.redirect_url) {
                const decode = router.query.redirect_url as string;
                const url = decodeURIComponent(decode);
                const cleanedUrl = url.substring(1, url.length - 1);
                return router.push(cleanedUrl);
              } else {
                return router.push(
                  `/orders/${PackageStatus.shipstreak}/${OrderStatuses.newpending}`
                );
              }
            }
          },
          onError: (err: any) => {
            toast.error(err.response?.data?.status);
          },
        }
      );
    },
    [loginWithPasswordQuery, router]
  );

  const handleSendOTP = useCallback(
    (values: any, actions: any, sendOtpCb: any) => {
      sendOTPQuery.execute(
        {
          phoneNumber: values.phoneNumber,
        },
        {
          onSuccess: (res: any) => {
            if (res.status === 200 && sendOtpCb) {
              if (!res.data.registered || !res.data.storeCreated) {
                toast.error(
                  'User is not registered, redirecting to signup page.'
                );
                setTimeout(() => {
                  router.replace('/signup');
                }, 1000);
              } else {
                setOTPAuthToken(res.data.authToken);
                sendOtpCb(values.phoneNumber);
              }
            }
          },
          onError: (err: any) => {
            toast.error(err.response?.data?.status);
          },
        }
      );
    },
    [sendOTPQuery]
  );

  const handleVerifyOTP = useCallback(
    (values: any) => {
      verifyOTPQuery.execute(
        {
          phoneNumber: values.phoneNumber,
          password: values.passwordLine1,
          email: values.email,
          otp: values.otp,
          otpAuthToken,
        },
        {
          onSuccess: (res: any) => {
            if (res.status === 200 || res.status === 201) {
              setCookie(
                null,
                COOKIES.shop101Session,
                JSON.stringify(res.data),
                getCookieDomain(window.location.hostname),
                null
              );
              setCookie(
                null,
                COOKIES.shop101LastUsedUsername,
                values.phoneNumber,
                getCookieDomain(window.location.hostname),
                180
              );
              if (router.query.client_id) {
                return router.push({
                  pathname: '/freshdesk/login',
                  query: router.query,
                });
              } else if (router.query.redirect_url) {
                const decode = router.query.redirect_url as string;
                const url = decodeURIComponent(decode);
                const cleanedUrl = url.substring(1, url.length - 1);
                return router.push(cleanedUrl);
              } else {
                return router.push(
                  `/orders/${PackageStatus.shipstreak}/${OrderStatuses.newpending}`
                );
              }
            }
          },
          onError: (err: any) => {
            toast.error(err.response?.data?.status);
          },
        }
      );
    },
    [otpAuthToken, router, verifyOTPQuery]
  );

  const handleResendOTP = useCallback(
    (resendOtpCb: any) => {
      resendOTPQuery.execute(
        {
          otpAuthToken,
        },
        {
          onSuccess: (res: any) => {
            if (res.status === 200 && resendOtpCb) {
              resendOtpCb();
            }
          },
          onError: (err: any) => {
            toast.error(err.response?.data?.status);
          },
        }
      );
    },
    [otpAuthToken, resendOTPQuery]
  );

  return useMemo(() => {
    return {
      handleSendOTP,
      handleVerifyOTP,
      handleLoginWithPassword,
      handleResendOTP,
      isLoading:
        loginWithPasswordQuery.isPending ||
        sendOTPQuery.isPending ||
        resendOTPQuery.isPending ||
        verifyOTPQuery.isPending,
    };
  }, [
    handleSendOTP,
    handleVerifyOTP,
    handleLoginWithPassword,
    handleResendOTP,
    loginWithPasswordQuery.isPending,
    sendOTPQuery.isPending,
    resendOTPQuery.isPending,
    verifyOTPQuery.isPending,
  ]);
};

export const [LoginPageProvider, useLoginPageContext] = generateContext(
  useLoginPage as unknown as UseGetContextValue
);

export default { LoginPageProvider, useLoginPageContext };
