import api from '@/utils/api';

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/OTP`;

interface SendOtpApiParams {
  phoneNumber?: string;
}

const sendOtpApi = ({ phoneNumber = '' }: SendOtpApiParams) => {
  return api.call({
    url: `${HOST}/user?mobileNumber=${phoneNumber}`,
    method: 'post',
  });
};

interface ResendOtpApiParams {
  otpAuthToken?: string;
}

const resendOtpApi = ({ otpAuthToken = '' }: ResendOtpApiParams) => {
  const headers: { [key: string]: string } = {};
  headers['x-auth-token'] = otpAuthToken;
  return api.call({
    url: `${HOST}/resend`,
    method: 'post',
    headers,
  });
};

export default {
  sendOtpApi,
  resendOtpApi,
};
