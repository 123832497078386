import { forwardRef, Ref } from 'react';
import { useField, useFormikContext } from 'formik';
import cx from '../../utils/class-names';
import FormError from '../FormError';

interface InputProps {
  classNames?: {
    input?: string;
    iconLeft?: string;
    iconRight?: string;
    root?: string;
  };
  className?: string;
  error?: React.ReactElement<{ message?: string }> | null;
  iconLeft?: React.ReactNode | null;
  iconRight?: React.ReactNode | null;
  readOnly?: boolean;
  disabled?: boolean;
  subNote?: React.ReactNode | null;
  [restProps: string]: any;
}

const Input = forwardRef(function Input(
  props: InputProps,
  ref: Ref<HTMLInputElement>
) {
  const {
    classNames = {},
    className,
    error,
    iconLeft = null,
    iconRight = null,
    readOnly = false,
    disabled = false,
    subNote = null,
    ...restProps
  } = props;

  const inputClasses = cx(
    'input input-bordered w-full input-sm',
    iconRight && 'pr-15',
    iconLeft && 'pl-15',
    !(subNote || error?.props?.message) && 'mb-3',
    (readOnly || disabled) && 'bg-secondary-250',
    classNames.input || className
  );

  const iconClasses = cx(
    'absolute top-1/2 -translate-y-1/2 h-7 flex items-center justify-center pb-0.5 mr-[1px]'
  );

  const iconLeftClasses = cx(iconClasses, 'left-0', classNames.iconLeft);
  const iconRightClasses = cx(iconClasses, 'right-0', classNames.iconRight);
  const rootClasses = cx('relative w-full', classNames.root);

  return (
    <div className={rootClasses}>
      {iconLeft && <div className={iconLeftClasses}>{iconLeft}</div>}
      <input
        ref={ref}
        type='text'
        className={inputClasses}
        {...restProps}
        readOnly={readOnly}
        disabled={disabled}
      />
      {iconRight && <div className={iconRightClasses}>{iconRight}</div>}
      {error?.props?.message || subNote ? (
        <label className='label px-0 pt-[2px]'>
          {error?.props?.message ? (
            <span className='label-text-alt h-[1em] text-red-400'>
              {error?.props?.message}
            </span>
          ) : null}
          {subNote ? <span className='label-text-alt'>{subNote}</span> : null}
        </label>
      ) : null}
    </div>
  );
});

export interface FormikInputProps {
  name: string;
  classNames?: {
    error?: string;
  };
  setRef?: (ref: HTMLInputElement | null) => void;
  subNote?: React.ReactNode | null;
  [restProps: string]: any;
}

export function FormikInput(props: FormikInputProps) {
  const {
    name,
    classNames = {},
    setRef = () => {},
    subNote = null,
    ...restProps
  } = props;

  const [field, meta] = useField(name);
  const { submitCount } = useFormikContext();
  const isError = submitCount > 0 && !!meta.error;
  const helperText = (submitCount > 0 && meta.error) || '';

  return (
    <>
      <Input
        ref={setRef}
        {...field}
        {...restProps}
        error={
          <FormError
            show={isError}
            message={helperText}
            className={classNames.error}
          />
        }
        subNote={subNote}
      />
    </>
  );
}

export default Input;
