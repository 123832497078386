import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import shop101Logo from 'assets/Shop101.png';
import {
  LoginPageProvider,
  useLoginPageContext,
} from '@/providers/loginProvider';
import withHOC from '@/utils/with-hoc';
import Image from 'next/legacy/image';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { FormikInput } from '@/components/Input';
import Eye from '@/components/vectors/Eye';
import { useRouter } from 'next/router';

const Loader = dynamic(() => import('components/Loader'));
const RedAlert = dynamic(() => import('components/RedAlert'));

const FIELD_NAMES = {
  PHONE_NUMBER: 'phoneNumber',
  PHONE_NUMBER_EMAIL: 'phoneNumberEmail',
  REMEMBER_ME: 'rememberMe',
  EMAIL: 'email',
  PASSWORD_LINE_1: 'passwordLine1',
  PASSWORD_LINE_2: 'passwordLine2',
  OTP: 'otp',
};

const validationSchemaLogin = Yup.object({
  [FIELD_NAMES.PHONE_NUMBER_EMAIL]: Yup.string().required(
    'This field is required'
  ),
  [FIELD_NAMES.PASSWORD_LINE_1]: Yup.string()
    .min(6, 'Minimum 6 characters')
    .required('This field is required'),
});

const validationSchemaVerify = Yup.object({
  [FIELD_NAMES.PHONE_NUMBER]: Yup.string()
    .matches(/^[6-9]/, 'Mobile number should start with 6, 7, 8 or 9')
    .length(10, 'Mobile number should be a 10 digit number')
    .required('This field is required'),
});

const validationSchemaSetNewPassword = Yup.object({
  [FIELD_NAMES.PHONE_NUMBER]: Yup.string()
    .matches(/^[6-9]/, 'Mobile number should start with 6, 7, 8 or 9')
    .length(10, 'Mobile number should be a 10 digit number')
    .required('This field is required'),
  [FIELD_NAMES.EMAIL]: Yup.string()
    .matches(/^[\w\d_~+.\-@]+$/, 'Must use valid characters')
    .email('Email is not valid')
    .trim('This field is required')
    .required('This field is required'),
  [FIELD_NAMES.PASSWORD_LINE_1]: Yup.string()
    .min(6, 'Minimum 6 characters')
    .required('This field is required'),
  [FIELD_NAMES.PASSWORD_LINE_2]: Yup.string()
    .min(6, 'Minimum 6 characters')
    .required('This field is required'),
  [FIELD_NAMES.OTP]: Yup.string()
    .min(6, 'Minimum 6 characters')
    .required('This field is required'),
});

const LoginPage = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [resendTimer, setResendTimer] = useState(0);
  const [timerIntervalId, setTimerIntervalId]: [any, Function] = useState(0);
  const [passwordToggle, setPasswordToggle] = useState([false, false]);

  const router = useRouter();

  const {
    handleLoginWithPassword,
    handleSendOTP,
    handleVerifyOTP,
    handleResendOTP,
    isLoading,
  } = useLoginPageContext();

  const resendTimerPollingFunction = () => {
    setResendTimer((resendTimer) => resendTimer - 1);
  };

  const startResendCount = () => {
    setResendTimer(60);
    setTimerIntervalId(setInterval(resendTimerPollingFunction, 1000));
  };

  useEffect(() => {
    if (resendTimer === 0) {
      clearInterval(timerIntervalId);
    }
  }, [resendTimer]);

  const login = (
    <div
      className={
        'm-auto max-w-[368px] rounded-[10px] p-[20px] shadow-[0_0_30px_0px_rgba(0,0,0,0.1)]'
      }
    >
      <span className={'mt-[10px] text-2xl font-bold'}>Login</span>
      <hr className={'my-[20px]'} />
      <Formik
        enableReinitialize
        initialValues={{
          [FIELD_NAMES.PHONE_NUMBER_EMAIL]: '',
          [FIELD_NAMES.PASSWORD_LINE_1]: '',
          [FIELD_NAMES.REMEMBER_ME]: false,
        }}
        validationSchema={validationSchemaLogin}
        onSubmit={handleLoginWithPassword}
      >
        {(formikProps) => (
          <Form
            onSubmit={formikProps.handleSubmit}
            placeholder={undefined}
          >
            <div className='form-control relative h-fit'>
              <label
                htmlFor={FIELD_NAMES.PHONE_NUMBER_EMAIL}
                className={'text-xs font-semibold'}
              >
                Email / Phone Number
              </label>
              <FormikInput
                className='w-full'
                id={FIELD_NAMES.PHONE_NUMBER_EMAIL}
                name={FIELD_NAMES.PHONE_NUMBER_EMAIL}
                placeholder='e.g. 9875450022 or someone@xyz.com'
                type={'text'}
                subNote={
                  <span className='label-text-alt opacity-50'>
                    Do not prefix +91 or 0
                  </span>
                }
              />
            </div>
            <label className={'font-semibold'}>Password</label>
            <div className={'relative'}>
              <FormikInput
                className={`w-full pl-2 ${
                  !passwordToggle[0]
                    ? 'font-[caption] text-xl tracking-wider'
                    : ''
                }`}
                id={FIELD_NAMES.PASSWORD_LINE_1}
                name={FIELD_NAMES.PASSWORD_LINE_1}
                type={passwordToggle[0] ? 'text' : 'password'}
              />
              <button
                type={'button'}
                className={
                  'btn btn-ghost btn-sm absolute right-0 top-0 rounded-l-none'
                }
                onClick={() => {
                  setPasswordToggle((prevState) => [
                    !prevState[0],
                    prevState[1],
                  ]);
                }}
              >
                <Eye />
              </button>
            </div>

            <div className={'flex justify-between'}>
              <label className={'flex'}>
                <input
                  className={'mr-2'}
                  type={'checkbox'}
                  value={formikProps.values[FIELD_NAMES.REMEMBER_ME].toString()}
                  onClick={() => {
                    formikProps.setFieldValue(
                      FIELD_NAMES.REMEMBER_ME,
                      !formikProps.values[FIELD_NAMES.REMEMBER_ME]
                    );
                  }}
                />
                Remember me
              </label>
              <a
                className={'underline hover:cursor-pointer'}
                onClick={() => {
                  setPageIndex(1);
                }}
              >
                Reset Password?
              </a>
            </div>

            <div className={'mt-10'}>
              <small className='text-xs text-[#757575]'>
                By continuing you agree to the
                <a
                  href='https://www.shop101.com/static/termsOfService.html'
                  rel='noreferrer'
                  target={'_blank'}
                >
                  {' '}
                  <b className='underline'>Roposo B2C TnC</b>
                </a>
                ,{' '}
                <a
                  href='https://www.shop101.com/static/b2bTermsOfService.html'
                  rel='noreferrer'
                  target={'_blank'}
                >
                  {' '}
                  <b className='underline'>Roposo B2B TnC </b>
                </a>
                &{' '}
                {/*<a href="https://www.shop101.com/static/termsOfService.html#seller"*/}
                {/*   target={"_blank"}*/}
                {/*   rel="noreferrer">*/}
                {/*    {" "}*/}
                {/*    <b className="underline">{"T&C"}</b>{" "}*/}
                {/*</a>*/}
                {/*and*/}
                <a
                  href='https://www.shop101.com/static/privacyPolicy.html'
                  rel='noreferrer'
                  target={'_blank'}
                >
                  {' '}
                  <b className='underline'>Privacy Policy</b>
                </a>
                .
              </small>
              <button
                type={'submit'}
                id='login-page-get-otp-button'
                className={'btn btn-primary mt-5 w-full'}
              >
                Login
              </button>
              <div className={'mt-5 text-center'}>
                <a
                  className={'underline hover:cursor-pointer'}
                  onClick={() => {
                    router.replace('/signup');
                  }}
                >
                  Register as a new user
                </a>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

  const verify = (
    <div
      className={
        'm-auto w-[368px] rounded-[10px] p-[20px] shadow-[0_0_30px_0px_rgba(0,0,0,0.1)]'
      }
    >
      <span className={'mt-[10px] text-2xl font-bold'}>Verify OTP</span>
      <hr className={'my-[20px]'} />
      <Formik
        enableReinitialize
        initialValues={{
          [FIELD_NAMES.PHONE_NUMBER]: '',
        }}
        validationSchema={validationSchemaVerify}
        onSubmit={(...args) => {
          handleSendOTP(...args, (phoneNumber: string) => {
            startResendCount();
            setPageIndex(2);
            setPhoneNumber(phoneNumber);
          });
        }}
      >
        {(formikProps) => (
          <Form
            onSubmit={formikProps.handleSubmit}
            placeholder={undefined}
          >
            <label className={'text-xs font-semibold'}>Phone Number</label>
            <FormikInput
              className='w-full'
              id={FIELD_NAMES.PHONE_NUMBER}
              name={FIELD_NAMES.PHONE_NUMBER}
              placeholder='e.g. 9812312311'
              type={'text'}
              subNote={
                <span className='label-text-alt opacity-50'>
                  Do not prefix +91 or 0
                </span>
              }
            />

            <div>
              <button
                type={'submit'}
                id='login-page-get-otp-button'
                className={'btn btn-primary mt-5 w-full'}
              >
                Send OTP
              </button>
            </div>
            <div className={'mt-5 text-center'}>
              <a
                className={'underline hover:cursor-pointer'}
                onClick={() => {
                  setPageIndex(0);
                }}
              >
                I remember the password
              </a>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

  const setNewPassword = (
    <div
      className={
        'm-auto w-[368px] rounded-[10px] p-[20px] shadow-[0_0_30px_0px_rgba(0,0,0,0.1)]'
      }
    >
      <span className={'mt-[10px] text-2xl font-bold'}>Login</span>
      <hr className={'my-[20px]'} />
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            [FIELD_NAMES.PHONE_NUMBER]: phoneNumber,
            [FIELD_NAMES.OTP]: '',
            [FIELD_NAMES.EMAIL]: '',
            [FIELD_NAMES.PASSWORD_LINE_1]: '',
            [FIELD_NAMES.PASSWORD_LINE_2]: '',
          }}
          validationSchema={validationSchemaSetNewPassword}
          onSubmit={(values, actions) => {
            if (
              values[FIELD_NAMES.PASSWORD_LINE_1] ===
              values[FIELD_NAMES.PASSWORD_LINE_2]
            ) {
              handleVerifyOTP(values, actions);
            } else {
              actions.setFieldError(
                FIELD_NAMES.PASSWORD_LINE_2,
                'Passwords do not match'
              );
            }
          }}
        >
          {(formikProps) => (
            <Form
              onSubmit={formikProps.handleSubmit}
              placeholder={undefined}
            >
              <div>
                <label className={'text-xs font-semibold'}>Phone No.</label>
                <div className={'relative text-xs'}>
                  <FormikInput
                    className='w-full'
                    id={FIELD_NAMES.PHONE_NUMBER}
                    name={FIELD_NAMES.PHONE_NUMBER}
                    placeholder='e.g. 9812312311'
                    type={'text'}
                    disabled
                  />
                  <a
                    className={
                      'absolute right-2 top-2 underline hover:cursor-pointer'
                    }
                    onClick={() => {
                      setPageIndex(0);
                    }}
                  >
                    Edit
                  </a>
                </div>
              </div>
              <div className={''}>
                <div className={'flex justify-between'}>
                  <label className={'font-semibold'}>Enter 6 digit OTP</label>
                  <span className={'text-xs'}>
                    <a
                      id='login-page-resend-otp-button'
                      className={`underline hover:cursor-pointer ${
                        resendTimer > 0 ? 'pointer-events-none opacity-50' : ''
                      }`}
                      onClick={() => {
                        handleResendOTP(startResendCount);
                      }}
                    >
                      Resend OTP
                    </a>
                    {resendTimer > 0 && <span> in {resendTimer} Sec</span>}
                  </span>
                </div>
                <FormikInput
                  className='w-full pl-2'
                  id={FIELD_NAMES.OTP}
                  name={FIELD_NAMES.OTP}
                  type={'text'}
                />
                <label className={'font-semibold'}>Email</label>
                <FormikInput
                  className='w-full pl-2'
                  id={FIELD_NAMES.EMAIL}
                  name={FIELD_NAMES.EMAIL}
                  type={'text'}
                />
                <label className={'font-semibold'}>New Password</label>
                <div className={'relative'}>
                  <FormikInput
                    className={`w-full pl-2 ${
                      !passwordToggle[0]
                        ? 'font-[caption] text-xl tracking-wider'
                        : ''
                    }`}
                    id={FIELD_NAMES.PASSWORD_LINE_1}
                    name={FIELD_NAMES.PASSWORD_LINE_1}
                    type={passwordToggle[0] ? 'text' : 'password'}
                  />
                  <button
                    type={'button'}
                    className={
                      'btn btn-ghost btn-sm absolute right-0 top-0 rounded-l-none'
                    }
                    onClick={() => {
                      setPasswordToggle((prevState) => [
                        !prevState[0],
                        prevState[1],
                      ]);
                    }}
                  >
                    <Eye />
                  </button>
                </div>
                <label className={'font-semibold'}>Confirm New Password</label>
                <div className={'relative'}>
                  <FormikInput
                    className={`w-full pl-2 ${
                      !passwordToggle[1]
                        ? 'font-[caption] text-xl tracking-wider'
                        : ''
                    }`}
                    id={FIELD_NAMES.PASSWORD_LINE_2}
                    name={FIELD_NAMES.PASSWORD_LINE_2}
                    type={passwordToggle[1] ? 'text' : 'password'}
                  />
                  <button
                    type={'button'}
                    className={
                      'btn btn-ghost btn-sm absolute right-0 top-0 rounded-l-none'
                    }
                    onClick={() => {
                      setPasswordToggle((prevState) => [
                        prevState[0],
                        !prevState[1],
                      ]);
                    }}
                  >
                    <Eye />
                  </button>
                </div>
                <button
                  id='login-page-proceed-button'
                  className={'btn btn-primary mt-2 w-full'}
                  type={'submit'}
                >
                  Login
                </button>
              </div>
              <div className={'mt-5 text-center'}>
                <a
                  className={'underline hover:cursor-pointer'}
                  onClick={() => {
                    setPageIndex(0);
                  }}
                >
                  I remember the password
                </a>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );

  return (
    <div
      className={'flex h-screen w-screen items-center justify-center text-sm'}
    >
      <div className='fixed top-0 z-20 w-full'>
        <RedAlert
          message="⚠️ We'll be undergoing temporary maintenance on Friday, 23rd Feb 2024, from 1:00 AM to 2:00 AM. We appreciate your understanding."
          expiryDateTime='2024-02-23 02:00:00'
        />
      </div>
      <div className={'w-full p-2'}>
        <div className={'mb-4 text-center'}>
          <Image
            src={shop101Logo}
            alt={'shop101-logo'}
            loading='eager'
            width={100}
            height={100}
            className={'m-auto'}
          />
        </div>
        <div className={'mb-4 text-center'}>
          <div className={'mb-2.5 text-4xl font-bold text-primary'}>
            Welcome
          </div>
          <div className={'text-sm text-neutral-400'}>
            Please login to continue
          </div>
        </div>
        {pageIndex === 0 && login}
        {pageIndex === 1 && verify}
        {pageIndex === 2 && setNewPassword}
        <Loader isVisible={isLoading} />
      </div>
    </div>
  );
};

export default withHOC(LoginPageProvider, LoginPage);
