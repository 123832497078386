import api from '@/utils/api';

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/sessions`;

interface LoginWithPasswordApiParams {
  phoneNumberEmail?: string;
  password?: string;
}

const loginWithPasswordApi = ({
  phoneNumberEmail = '',
  password = '',
}: LoginWithPasswordApiParams) => {
  const headers: { [key: string]: string } = {};

  if (phoneNumberEmail.split('@').length > 1) {
    headers['X-Auth-Email'] = phoneNumberEmail;
  } else {
    headers['X-Auth-Mobile'] = phoneNumberEmail;
  }

  headers['x-auth-passwordhash'] = password;

  return api.call({
    url: `${HOST}/supplierSignIn`,
    method: 'post',
    headers,
  });
};

export default {
  loginWithPasswordApi,
};
